<template>
<div>
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <v-toolbar-title>TMS Route</v-toolbar-title>
  </v-app-bar>

  <div
    style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;"
  >
    <v-tabs style="width: 100%;"
      grow
      v-model="tab_select">
      <v-tab>ToDo</v-tab>
    </v-tabs>

    <v-list two-line>
        <v-list-item-group
          multiple
        >
          <template v-for="(item, index) in items">
            <v-list-item :key="item.id + 'v-list-item'" @click="goRouteDetail(item.id)">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title v-text="'Route #' + item.id + ' | ' + item.driving_date"></v-list-item-title>
                  <v-list-item-subtitle class="text--primary" v-text="strRouteInfo1(item)"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="'Note: ' + item.notes"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text v-text="'#' + item.id"></v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="index + 1 < items.length"
              :key="item.id + 'v-divider'"
            ></v-divider>
          </template>

          <template v-if="items.length > 0">
            <v-divider
                :key="'v-divider-next_item_btn_top'"
              ></v-divider>

            <v-list-item :key="'next_item_btn'" @click="goGetNext()" v-if="has_next_page">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title style="color: #1867c0;" v-text="'Click to load more ...'"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-list-item :key="'next_item_btn_2'" v-if="has_next_page === false">
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title style="color: #1867c0;" v-text="'End'"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>

          <v-list-item :key="'next_item_btn_3'" v-if="show_no_data">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title style="color: #1867c0;" v-text="'No Data'"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>

        </v-list-item-group>
      </v-list>
  </div>

  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
  >
    {{ snackbar_txt }}
    <v-btn
      style="display: none;"
      color="pink"
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { get } from '@/utils/request.js'
import {EH_MOBILE_GLOBAL} from '@/helper.js'
import global_ from '@/utils/Global'

export default {
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,
      null_val: null,

      page: 1,
      has_next_page: null,
      route_list: {},
      items: [],
      tab_select: 0,
      show_no_data: false,
      driver: {},
    }
  },
  watch: {
    tab_select (newval, oldval) {
      this.null_val = newval
      this.null_val = oldval
      this.page = 0
      this.items = []
      this.route_list = {}
      this.getRouteList()

      global_.current_tms_route_table = newval
    },
  },
  methods: {
    getRouteList: function () {
      let url = EH_MOBILE_GLOBAL.get_route_list + '?page=' + this.page + '&day_type=' + this.tab_select
      get(url, null, {}).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        for (let i in data.data.data) {
          let tmp_route = data.data.data[i]
          this.route_list[tmp_route['id']] = tmp_route
        }

        console.log(data.data.data)
        console.log(this.route_list)

        this.items = []
        for (let i2 in this.route_list) {
          this.items.push(this.route_list[i2])
        }

        console.log(this.items)

        if (data.data.next_page_url) {
          this.has_next_page = true
        } else {
          this.has_next_page = false
        }

        if (data.data.total == 0) {
          this.show_no_data = true
        } else {
          this.show_no_data = false
        }

        this.driver = data.driver_info
        // console.log(this.items)
      })
    },
    // strTruckInfo: function (truck_info) {
    //   return 'Plate: ' + truck_info['plate'] + ', Model: ' + truck_info['model'] + ', Brand: ' + truck_info['brand']
    // },
    strRouteInfo1: function (item) {
      return this.driver['full_name'] + ' | ' + item['truck_info']['plate'] + ' | ' + item['truck_info']['brand'] + ' | ' + item['truck_info']['model']
    },
    goGetNext: function () {
      this.page += 1
      this.getRouteList()
    },
    goRouteDetail: function (route_id) {
      let path = '/tms/order/list/' + route_id
      this.$router.push({
        path: path
      })
    }
  },
  created () {
    if (global_.current_tms_route_table > 0) {
      this.tab_select = global_.current_tms_route_table
    }
    this.getRouteList()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
